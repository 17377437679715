import { Chart, registerables } from 'chart.js';
window.Chart = Chart;
import ChartDataLabels from 'chartjs-plugin-datalabels';
window.ChartDataLabels = ChartDataLabels;
Chart.register(...registerables);

let showingOriginalLabels = true;
let investedByCountryChart;
let investedByCountryCurrentChart;
let investedByCurrencyChart;
let investedByCurrencyCurrentChart;

let originalLabelsByCountry;
let originalLabelsByCountryCurrent;
let originalLabelsByCurrency;
let originalLabelsByCurrencyCurrent;

let dataByCountry;
let dataByCountryCurrent;
let dataByCurrency;
let dataByCurrencyCurrent;

document.addEventListener('turbo:load', () => {
    initializeCharts();
    const changeLegendButton = document.getElementById('changeLegendButton');
    if (changeLegendButton) {
        changeLegendButton.addEventListener('click', changeLegend);
    }
});

export function initializeCharts() {
    const originalLabelsByCountryElement = document.getElementById('originalLabelsByCountry');
    const dataByCountryElement = document.getElementById('dataByCountry');
    const originalLabelsByCountryCurrentElement = document.getElementById('originalLabelsByCountryCurrent');
    const dataByCountryCurrentElement = document.getElementById('dataByCountryCurrent');
    const originalLabelsByCurrencyElement = document.getElementById('originalLabelsByCurrency');
    const dataByCurrencyElement = document.getElementById('dataByCurrency');
    const originalLabelsByCurrencyCurrentElement = document.getElementById('originalLabelsByCurrencyCurrent');
    const dataByCurrencyCurrentElement = document.getElementById('dataByCurrencyCurrent');

    if (originalLabelsByCountryElement && dataByCountryElement && originalLabelsByCountryCurrentElement && dataByCountryCurrentElement && originalLabelsByCurrencyElement && dataByCurrencyElement && originalLabelsByCurrencyCurrentElement && dataByCurrencyCurrentElement) {
        originalLabelsByCountry = JSON.parse(originalLabelsByCountryElement.textContent);
        dataByCountry = JSON.parse(dataByCountryElement.textContent);
        originalLabelsByCountryCurrent = JSON.parse(originalLabelsByCountryCurrentElement.textContent);
        dataByCountryCurrent = JSON.parse(dataByCountryCurrentElement.textContent);
        originalLabelsByCurrency = JSON.parse(originalLabelsByCurrencyElement.textContent);
        dataByCurrency = JSON.parse(dataByCurrencyElement.textContent);
        originalLabelsByCurrencyCurrent = JSON.parse(originalLabelsByCurrencyCurrentElement.textContent);
        dataByCurrencyCurrent = JSON.parse(dataByCurrencyCurrentElement.textContent);

        if (investedByCountryChart) investedByCountryChart.destroy();
        if (investedByCountryCurrentChart) investedByCountryCurrentChart.destroy();
        if (investedByCurrencyChart) investedByCurrencyChart.destroy();
        if (investedByCurrencyCurrentChart) investedByCurrencyCurrentChart.destroy();

        investedByCountryChart = createDoughnutChart('investedByCountryChart', 'Investice podle země - zainvestováno', originalLabelsByCountry, false, dataByCountry);
        investedByCountryCurrentChart = createDoughnutChart('investedByCountryCurrentChart', 'Investice podle země - aktuální hodnota', originalLabelsByCountryCurrent, false, dataByCountryCurrent);
        investedByCurrencyChart = createDoughnutChart('investedByCurrencyChart', 'Investice podle měny - zainvestováno', originalLabelsByCurrency, true, dataByCurrency);
        investedByCurrencyCurrentChart = createDoughnutChart('investedByCurrencyCurrentChart', 'Investice podle měny - aktuální hodnota', originalLabelsByCurrencyCurrent, true, dataByCurrencyCurrent);
    }
}

function createDoughnutChart(canvasId, chartTitle, labels, labelsInGraph, data) {
    const ctx = document.getElementById(canvasId).getContext('2d');
    return new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: colors.slice(0, labels.length),
                hoverBackgroundColor: colors.slice(0, labels.length)
            }]
        },
        plugins: [ChartDataLabels],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return '';
                        }
                    }
                },
                title: {
                    display: true,
                    text: chartTitle,
                    position: 'top',
                    align: 'center',
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                legend: {
                    position: 'left'
                },
                datalabels: {
                    display: labelsInGraph,
                    color: '#ffffff',
                    textStrokeColor: '#000000',
                    textStrokeWidth: 2,
                    textShadowColor: '#000000',
                    textShadowBlur: 3,
                    anchor: 'center',
                    align: 'center',
                    font: {
                        weight: 'bold'
                    },
                    formatter: function (value, context) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return label.substring(0, label.indexOf(" ")).trim(); // Vrátí pouze jméno země a procenta
                    }
                }
            },
        }
    });
}

function updateLabels(labels) {
    return labels.map(label => {
        const startIndex = label.indexOf("-");
        const endIndex = label.indexOf("(");
        if (startIndex !== -1 && endIndex !== -1) {
            return label.substring(0, startIndex).trim() + " " + label.substring(endIndex).trim();
        }
        return label;
    });
}

function changeLegend() {
    const labelsByCountry = showingOriginalLabels ? updateLabels(originalLabelsByCountry) : originalLabelsByCountry;
    const labelsByCountryCurrent = showingOriginalLabels ? updateLabels(originalLabelsByCountryCurrent) : originalLabelsByCountryCurrent;
    const labelsByCurrency = showingOriginalLabels ? updateLabels(originalLabelsByCurrency) : originalLabelsByCurrency;
    const labelsByCurrencyCurrent = showingOriginalLabels ? updateLabels(originalLabelsByCurrencyCurrent) : originalLabelsByCurrencyCurrent;

    investedByCountryChart.destroy();
    investedByCountryCurrentChart.destroy();
    investedByCurrencyChart.destroy();
    investedByCurrencyCurrentChart.destroy();

    investedByCountryChart = createDoughnutChart('investedByCountryChart', 'Investice podle země - zainvestováno', labelsByCountry, false, dataByCountry);
    investedByCountryCurrentChart = createDoughnutChart('investedByCountryCurrentChart', 'Investice podle země - aktuální hodnota', labelsByCountryCurrent, false, dataByCountryCurrent);
    investedByCurrencyChart = createDoughnutChart('investedByCurrencyChart', 'Investice podle měny - zainvestováno', labelsByCurrency, true, dataByCurrency);
    investedByCurrencyCurrentChart = createDoughnutChart('investedByCurrencyCurrentChart', 'Investice podle měny - aktuální hodnota', labelsByCurrencyCurrent, true, dataByCurrencyCurrent);

    showingOriginalLabels = !showingOriginalLabels;
}

const colors = [
    '#36A2EB', '#FFCE56', '#1d811d', '#4BC0C0', '#F77825', '#FF6384', '#9966FF', '#C9CBCF', '#FF9F40',
    '#FFCD56', '#4D5360', '#C0C0C0', '#808080', '#99FF99', '#3399FF', '#FF3333', '#FF3399',
    '#6666FF', '#00E7E7', '#B3B3B3', '#FF6666', '#70DB93', '#B366CC', '#4DB380', '#E6B333',
    '#3366E6', '#999966', '#99FF33', '#B34D4D', '#809980', '#E67300'
];
