import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    this.initializePopovers()
    document.addEventListener('turbo:frame-load', this.initializePopovers);
    document.addEventListener('simulation:updated', this.initializePopovers); // Listen for custom event
  }

  disconnect() {
    document.removeEventListener('turbo:frame-load', this.initializePopovers);
    document.addEventListener('simulation:updated', this.initializePopovers); // Listen for custom event
  }

  initializePopovers() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
      const popover = new bootstrap.Popover(popoverTriggerEl, {
        trigger: 'hover focus',
        html: true,
        placement: 'bottom'
      })
    })
  }
}
