import { Turbo } from "@hotwired/turbo-rails";
import '../controllers';

//Turbo.session.drive = false
Turbo.start()

import * as allocations from '../components/charts/allocations';

function initializeCharts() {
  if (document.querySelector('#investedByCurrencyChart')) {
    allocations.initializeCharts();
  }
}

document.addEventListener('turbo:load', () => {
  initializeCharts();
});

document.addEventListener('turbo:render', () => {
  initializeCharts();
});

document.addEventListener('DOMContentLoaded', () => {
  initializeCharts();
});
