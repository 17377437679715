import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["units", "unitPrice", "profit", "profitText", "unitsRange", "unitPriceRange", "isin", "currency", "unitPriceLabel"];

    connect() {
        this.calculateProfit();
        this.updateLimits(false);
        this.dispatchEvent(); // Dispatch event to initialize popovers

    }

    calculateProfit() {
        const units = parseFloat(this.unitsTarget.value) || 0;
        const unitPrice = parseFloat(this.unitPriceTarget.value) || 0;
        const profit = (units * unitPrice);

        this.profitTextTarget.textContent = profit.toLocaleString('cs-CZ', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

        // Update range inputs to match number input values
        this.unitsRangeTarget.value = units;
        this.unitPriceRangeTarget.value = unitPrice;
    }

    updateUnitsFromRange() {
        this.unitsTarget.value = this.unitsRangeTarget.value;
        this.calculateProfit();
    }

    updateUnitPriceFromRange() {
        this.unitPriceTarget.value = this.unitPriceRangeTarget.value;
        this.calculateProfit();
    }

    updateUnitsFromInput() {
        this.unitsRangeTarget.value = this.unitsTarget.value;
        this.calculateProfit();
    }

    updateUnitPriceFromInput() {
        this.unitPriceRangeTarget.value = this.unitPriceTarget.value;
        this.calculateProfit();
    }

    updateLimits(shouldResetValues = true) {
        const selectedOption = this.isinTarget.options[this.isinTarget.selectedIndex];
        const unitsMax = parseFloat(selectedOption.dataset.units);
        const unitPriceAvg = parseFloat(selectedOption.dataset.priceAverage);
        const currencyCode = selectedOption.dataset.currencyCode;

        this.unitsTarget.max = unitsMax;
        this.unitsRangeTarget.max = unitsMax;

        const minPrice = (unitPriceAvg * 0.1).toFixed(0);
        const maxPrice = (unitPriceAvg * 2).toFixed(0);

        this.unitPriceTarget.min = minPrice;
        this.unitPriceTarget.max = maxPrice;
        this.unitPriceRangeTarget.min = minPrice;
        this.unitPriceRangeTarget.max = maxPrice;

        this.currencyTarget.textContent = currencyCode;
        this.unitPriceLabelTarget.textContent = `Cena za kus v ${currencyCode}`;

        if (shouldResetValues || !this.unitsTarget.value || !this.unitPriceTarget.value) {
            this.unitsTarget.value = unitsMax;
            this.unitsRangeTarget.value = unitsMax;
            this.unitPriceTarget.value = unitPriceAvg;
            this.unitPriceRangeTarget.value = unitPriceAvg;
        }

        this.calculateProfit();
    }

    resetSimulation() {
        // Hide simulation results
        document.getElementById('simulation-results').classList.add('d-none');
    }

    dispatchEvent() {
        const event = new Event('simulation:updated');
        document.dispatchEvent(event);
    }
}
