import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        portfolioId: String
    }

    connect() {
        //console.log("Status controller connected at", new Date().toLocaleString());
        //console.log("Portfolio ID:", this.portfolioIdValue);
        this.isPolling = false;  // Přidání stavu pro sledování polling
        this.startPolling();
    }

    disconnect() {
        //console.log("Status controller disconnected at", new Date().toLocaleString());
        this.stopPolling();
    }

    startPolling() {
        if (!this.isPolling && this.hasRelevantStates()) {
            //console.log("Starting polling at", new Date().toLocaleString());
            this.isPolling = true;
            this.refreshInterval = setInterval(() => {
                this.fetchUpdates();
            }, 1200);
        }
    }

    stopPolling() {
        //console.log("Stopping polling at", new Date().toLocaleString());
        if (this.isPolling) {
            clearInterval(this.refreshInterval);
            this.isPolling = false;
        }
    }

    fetchUpdates() {
        //console.log("Fetching updates at", new Date().toLocaleString());
        const portfolioId = this.portfolioIdValue;
        const url = `/portfolios/${portfolioId}/flow_uploads/status_updates`;
        //console.log("URL for fetch:", url);
        fetch(url)
            .then(response => response.text())
            .then(html => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                Turbo.renderStreamMessage(doc.body.innerHTML);
                if (!this.hasRelevantStates()) {
                    this.stopPolling();
                }
            })
            .catch(error => console.error('Error fetching status updates:', error));
    }

    hasRelevantStates() {
        //console.log("Checking for relevant states at", new Date().toLocaleString());
        return document.querySelectorAll('turbo-frame[data-flow-upload-state="processing"], turbo-frame[data-flow-upload-state="waiting_for_processing"], turbo-frame[data-flow-upload-state="unknown"]').length > 0;
    }
}
