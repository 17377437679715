import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["product", "billingPeriodDesktop", "billingPeriodMobile", "monthlyPrice", "totalPrice"];

    connect() {
        this.updatePrices();
    }

    updatePrices() {
        let billingPeriod;

        if (this.hasBillingPeriodDesktopTarget && this.billingPeriodDesktopTargets.length > 0) {
            billingPeriod = this.billingPeriodDesktopTargets.find(
                (radio) => radio.checked
            )?.value;
        }

        if (!billingPeriod && this.hasBillingPeriodMobileTarget && this.billingPeriodMobileTargets.length > 0) {
            billingPeriod = this.billingPeriodMobileTargets.find(
                (radio) => radio.checked
            )?.value;
        }

        // Pokračování s výpočtem cen pouze v případě, že billingPeriod má hodnotu
        if (billingPeriod) {
            const prices = {
                maximal: { year: { monthly: 399, total: 4788 }, quarter: { monthly: 799, total: 2397 } },
                optimal: { year: { monthly: 299, total: 3588 }, quarter: { monthly: 599, total: 1797 } },
                basic: { year: { monthly: 249, total: 2988 }, quarter: { monthly: 499, total: 1497 } },
            };

            /* Update prices for the product and period selection page - parameters page */
            if (this.hasProductTarget) {
                const product = this.productTarget.value;
                const priceData = prices[product][billingPeriod];

                this.monthlyPriceTarget.innerText = `${priceData.monthly} Kč`;
                this.totalPriceTarget.innerText = `${priceData.total.toFixed(0)} Kč`;
            }

            /* Update prices for the all products overview */
            const products = ["maximal", "optimal", "basic"];
            products.forEach((product) => {
                const monthlyPriceTarget = this.targets.find(
                    `${product}MonthlyPrice`
                );
                const priceData = prices[product][billingPeriod];
                if (monthlyPriceTarget) {
                    monthlyPriceTarget.innerText = `${priceData.monthly} Kč`;
                }
            });
        } else {
            console.error("Nenalezen žádný označený radio button.");
        }
    }

}
